/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import CustomLink from 'components/CustomLink'
import { Paragraph } from 'components/StyledComponents'
import redChevronRight from 'img/snel_red_chevron_right.svg'
import styled from 'styled-components'
import parse from 'html-react-parser'

const Title = styled.h1`
  & h1 {
    font-size: 40px;
  }
`

const VacaturesTemplate = ({ data: { page, vacancies }, pageContext }) => (
  <Layout>
    <SEO seo={page.seo} />
    <div className="position-relative">
      <CustomBreadCrumb
        data={page}
        className="py-2"
        pageContext={pageContext}
      />
      <div className="container py-5">
        <Title>{page.pageVacancies.content.title}</Title>

        <div className="row pt-5">
          {vacancies.edges.map((item, index) => (
            <Vacancy
              className="col-sm-6 col-lg-4"
              key={index}
              data={item.node}
            />
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

const StyledCustomLink = styled(CustomLink)``

const DescriptionContainer = styled.div`
  height: 67px;
  text-overflow: ellipsis;
  overflow: hidden;

  p {
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: 1.57;
    letter-spacing: -0.19px;
  }

  @media (max-width: 991px) {
    height: 68px;
  }
`

function Vacancy({ data, className = '' }) {
  return (
    <div className={`mb-4 ${className ? `${className}` : ``}`}>
      <StyledCustomLink to={`/vacatures/${data.slug}`}>
        <div className="py-3 px-2">
          <Paragraph className="font-weight-xl pb-2">{data.title}</Paragraph>
          <Paragraph className="font-size-s color-text-secondary">
            {data.date}
          </Paragraph>
          <DescriptionContainer>{parse(data.recapVacature.excerpt)}</DescriptionContainer>
          <div className="d-flex mt-3">
            <img className="pr-2" src={redChevronRight} alt="" />
            <Paragraph className="text-uppercase color-text-striking font-size-sm font-weight-xl">
              Bekijk vacature
            </Paragraph>
          </div>
        </div>
      </StyledCustomLink>
    </div>
  )
}

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      pageVacancies {
        content {
          title
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
    vacancies: allWpVacature(sort: { fields: date, order: DESC }) {
      edges {
        node {
          slug
          title
          date(formatString: "DD MMMM YYYY", locale: "nl-NL")
          recapVacature {
            excerpt
            description
          }
        }
      }
    }
  }
`

export default VacaturesTemplate
